var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-wrap" }, [
    _c(
      "div",
      { staticClass: "w-full ml-auto mr-auto", class: _vm.classObject },
      [
        _vm.pageTitle
          ? _c("h2", { staticClass: "center-container-heading mb-6" }, [
              _vm._v(_vm._s(_vm.pageTitle)),
            ])
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }