var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: _vm.pageTitle } },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Role Name*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:50",
                      expression: "'required|max:50'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "roleName", disabled: _vm.readOnly },
                  model: {
                    value: _vm.form.roleName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleName", $$v)
                    },
                    expression: "form.roleName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("roleName"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Role Description")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:150",
                      expression: "'max:150'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "roleDescription", disabled: _vm.readOnly },
                  model: {
                    value: _vm.form.roleDescription,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleDescription", $$v)
                    },
                    expression: "form.roleDescription",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("roleDescription"))),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "vx-card",
            { attrs: { "no-shadow": "", "card-border": "" } },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-end px-3" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: { svgClasses: "w-6 h-6", icon: "LockIcon" },
                        }),
                        _c(
                          "span",
                          { staticClass: "font-medium text-lg leading-none" },
                          [_vm._v("Permissions")]
                        ),
                      ],
                      1
                    ),
                    _c("vs-divider", { staticClass: "mb-0" }),
                  ],
                  1
                ),
              ]),
              _vm._l(
                Object.keys(_vm.permissionCategories),
                function (category, categoryIndex) {
                  return _c(
                    "div",
                    { key: categoryIndex },
                    [
                      _c("h6", { staticClass: "mt-6" }, [
                        _vm._v(_vm._s(category)),
                      ]),
                      _c("vs-divider", { staticClass: "mt-3 mb-0" }),
                      _vm._l(
                        Object.keys(_vm.getGroupedPermissionNames(category)),
                        function (permissionName, nameIndex) {
                          return _c(
                            "div",
                            { key: nameIndex, staticClass: "vx-row" },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col lg:w-1/5 w-full mt-2" },
                                [_c("span", [_vm._v(_vm._s(permissionName))])]
                              ),
                              _vm._l(
                                _vm.getPermissionFunctions(
                                  category,
                                  permissionName
                                ),
                                function (permission, permissionIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: permissionIndex,
                                      staticClass:
                                        "vx-col lg:w-1/5 w-full mt-2",
                                    },
                                    [
                                      _c(
                                        "vs-checkbox",
                                        {
                                          attrs: {
                                            title: permission.description,
                                            "vs-value": permission.id,
                                            disabled: _vm.readOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.form.selectedPermissionIds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "selectedPermissionIds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.selectedPermissionIds",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(permission.function) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c("vs-divider", {
                                staticClass: "mx-4 mb-0 mt-2",
                              }),
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  )
                }
              ),
            ],
            2
          ),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
              _vm.readOnly && !_vm.isSystemRole
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-4 mr-4",
                      on: { click: _vm.handleEditMode },
                    },
                    [_vm._v("Edit")]
                  )
                : _vm._e(),
              _vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleCancel } },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }