var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap items-center" },
    [
      _c("span", [_vm._v(_vm._s(this.params.data.applicationName))]),
      this.params.data.isDemo
        ? _c(
            "vs-chip",
            {
              staticClass: "ag-grid-cell-chip mt-5 ml-2",
              attrs: { color: "danger" },
            },
            [_c("span", [_vm._v("Demo")])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }