var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col xs:w-full lg:w-3/4" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap items-center" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mr-2 sm:mb-0",
                        on: { click: _vm.handleSubmit },
                      },
                      [_vm._v("Submit")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mr-2 sm:mb-0",
                        attrs: { color: "primary", type: "border" },
                        on: {
                          click: function ($event) {
                            _vm.showRolePrompt = true
                          },
                        },
                      },
                      [_vm._v("Set All Roles")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-col xs:w-full lg:w-1/4" },
                [
                  _c("vs-input", {
                    staticClass:
                      "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                    attrs: { placeholder: "Search..." },
                    on: { input: _vm.updateSearchQuery },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.usersData,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true,
              },
              on: { "selection-changed": _vm.onSelectionChanged },
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: 5 },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
        _c(
          "vs-prompt",
          {
            attrs: { title: "Set All Roles", active: _vm.showRolePrompt },
            on: {
              cancel: _vm.clearRolePrompt,
              close: _vm.clearRolePrompt,
              accept: _vm.setAllRoles,
              "update:active": function ($event) {
                _vm.showRolePrompt = $event
              },
            },
          },
          [
            _c("div", [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("p", [_vm._v("Select role to set all users")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [_vm._v("Title")]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.roleOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      model: {
                        value: _vm.selectedRole,
                        callback: function ($$v) {
                          _vm.selectedRole = $$v
                        },
                        expression: "selectedRole",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }