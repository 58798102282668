var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center w-full cell-renderer-select-container" },
    [
      _c("v-select", {
        attrs: {
          value: _vm.selectedRole,
          "append-to-body": "",
          options: _vm.roleOptions,
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
        },
        on: { input: _vm.setSelectedRole },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }