var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: "Add New Application" } },
    [
      _c("vx-card", [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center mb-base" },
          [
            _c("vs-avatar", {
              staticClass: "mr-4 mb-4 company-logo-avatar",
              attrs: {
                src: _vm.form.logoUrl,
                icon: "cloud_upload",
                size: "70px",
              },
              on: {
                click: function ($event) {
                  return _vm.$refs.companyLogoInput.click()
                },
              },
            }),
            _c(
              "div",
              [
                _c("input", {
                  ref: "companyLogoInput",
                  attrs: { type: "file", hidden: "", id: "companyLogoInput" },
                  on: { change: _vm.handleFileUpload },
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.companyLogoInput.click()
                      },
                    },
                  },
                  [_vm._v("Upload App Logo")]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { type: "border", color: "danger" },
                    on: { click: _vm.removeLogo },
                  },
                  [_vm._v("Remove")]
                ),
                _c("p", { staticClass: "text-sm mt-2" }, [
                  _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "flex items-end" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "SettingsIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("General"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Application Name*",
                    name: "applicationName",
                  },
                  model: {
                    value: _vm.form.applicationName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "applicationName", $$v)
                    },
                    expression: "form.applicationName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("applicationName"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Web Platform"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedWebPlatform,
                        options: _vm.webPlatformOptions,
                      },
                      on: { input: _vm.setSelectedWebPlatform },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4 mb-8" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Subscription*"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedSubscription,
                        options: _vm.subscriptionOptions,
                      },
                      on: { input: _vm.setSelectedSubscription },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex items-end" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("Location"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Region*"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedRegion,
                        options: _vm.regionOptions,
                      },
                      on: { input: _vm.setSelectedRegion },
                    }),
                  ],
                  1
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:250",
                      expression: "'max:250'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: { label: "Address 1", name: "address1" },
                  model: {
                    value: _vm.form.address1,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "address1", $$v)
                    },
                    expression: "form.address1",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("address1"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:250",
                      expression: "'max:250'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: { label: "Address 2", name: "address2" },
                  model: {
                    value: _vm.form.address2,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "address2", $$v)
                    },
                    expression: "form.address2",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("address2"))),
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'",
                          },
                        ],
                        staticClass: "w-full mt-4",
                        attrs: { label: "City", name: "city" },
                        model: {
                          value: _vm.form.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "city", $$v)
                          },
                          expression: "form.city",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("city"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'",
                          },
                        ],
                        staticClass: "w-full mt-4",
                        attrs: { label: "State", name: "state" },
                        model: {
                          value: _vm.form.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("state"))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Country"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedCountry,
                        options: _vm.countryOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: { input: _vm.setSelectedCountry },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "flex items-end md:mt-0 mt-base" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "CalendarIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("Booking"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Booking Email Name",
                    name: "bookingEmailName",
                  },
                  model: {
                    value: _vm.form.bookingEmailName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bookingEmailName", $$v)
                    },
                    expression: "form.bookingEmailName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("bookingEmailName"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:100|email",
                      expression: "'max:100|email'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: { label: "Booking Email", name: "bookingEmail" },
                  model: {
                    value: _vm.form.bookingEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bookingEmail", $$v)
                    },
                    expression: "form.bookingEmail",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("bookingEmail"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:150",
                      expression: "'max:150'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Booking Telephone Name",
                    name: "bookingTelephoneName",
                  },
                  model: {
                    value: _vm.form.bookingTelephoneName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bookingTelephoneName", $$v)
                    },
                    expression: "form.bookingTelephoneName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("bookingTelephoneName"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:20",
                      expression: "'max:20'",
                    },
                  ],
                  staticClass: "w-full mt-4 mb-8",
                  attrs: {
                    label: "Booking Telephone",
                    name: "bookingTelephone",
                  },
                  model: {
                    value: _vm.form.bookingTelephone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bookingTelephone", $$v)
                    },
                    expression: "form.bookingTelephone",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("bookingTelephone"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex items-end md:mt-0 mt-base" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("Suport"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Support Email Name",
                    name: "supportEmailName",
                  },
                  model: {
                    value: _vm.form.supportEmailName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supportEmailName", $$v)
                    },
                    expression: "form.supportEmailName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("supportEmailName"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:100|email",
                      expression: "'max:100|email'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: { label: "Support Email", name: "supportEmail" },
                  model: {
                    value: _vm.form.supportEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supportEmail", $$v)
                    },
                    expression: "form.supportEmail",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("supportEmail"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:150",
                      expression: "'max:150'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Support Telephone Name",
                    name: "supportTelephoneName",
                  },
                  model: {
                    value: _vm.form.supportTelephoneName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supportTelephoneName", $$v)
                    },
                    expression: "form.supportTelephoneName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("supportTelephoneName"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:20",
                      expression: "'max:20'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Support Telephone",
                    name: "supportTelephone",
                  },
                  model: {
                    value: _vm.form.supportTelephone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supportTelephone", $$v)
                    },
                    expression: "form.supportTelephone",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("supportTelephone"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mt-4 mr-4",
                attrs: { type: "border", color: "danger" },
                on: { click: _vm.handleCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }