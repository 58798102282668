var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-chip",
    {
      staticClass: "ag-grid-cell-chip",
      attrs: { color: _vm.chipColor(_vm.params.data.roleType) },
    },
    [_c("span", [_vm._v(_vm._s(_vm.params.data.roleType))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }