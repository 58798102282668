var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Edit Dashboard", gridWidth: "1/2" } },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Name*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:50",
                      expression: "'required|max:50'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Dashboard Name" },
                  model: {
                    value: _vm.dashboardName,
                    callback: function ($$v) {
                      _vm.dashboardName = $$v
                    },
                    expression: "dashboardName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Dashboard Name"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Description")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:150",
                      expression: "'max:150'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "dashboardDescription" },
                  model: {
                    value: _vm.dashboardDescription,
                    callback: function ($$v) {
                      _vm.dashboardDescription = $$v
                    },
                    expression: "dashboardDescription",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("dashboardDescription"))),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "vx-card",
            {
              staticClass: "mt-base",
              attrs: { "no-shadow": "", "card-border": "" },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-end px-3" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            svgClasses: "w-6 h-6",
                            icon: "ArrowDownIcon",
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "font-medium text-lg leading-none" },
                          [_vm._v("Widget Sorting")]
                        ),
                      ],
                      1
                    ),
                    _c("vs-divider", { staticClass: "mb-4" }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vs-list",
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "cursor-move",
                            attrs: { list: _vm.widgetList },
                          },
                          _vm._l(_vm.widgetList, function (widget, index) {
                            return _c(
                              "vs-list-item",
                              {
                                key: index,
                                staticClass: "flex items-center p-1",
                                attrs: {
                                  title: widget.name,
                                  subtitle: widget.title,
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "float-left",
                                  attrs: {
                                    slot: "avatar",
                                    icon: "MoreVerticalIcon",
                                    svgClasses: "w-6 h-6 text-grey",
                                  },
                                  slot: "avatar",
                                }),
                                _c("vs-avatar", {
                                  attrs: { slot: "avatar", text: widget.name },
                                  slot: "avatar",
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "float-left mt-6" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                { staticClass: "mt-4 mr-4", on: { click: _vm.handleSubmit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }