var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.userHasPermission("company_apps_edit")
        ? _c("feather-icon", {
            attrs: {
              title: "View Details",
              icon: "EyeIcon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.displayRecord },
          })
        : _vm._e(),
      _vm.userHasPermission("company_apps_edit")
        ? _c("feather-icon", {
            attrs: {
              title: "Edit",
              icon: "Edit3Icon",
              svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.onEdit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }