var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c("vs-avatar", {
        staticClass: "application-logo-avatar flex-shrink-0 mr-2",
        attrs: {
          src: this.params.data.logoUrl,
          text: this.params.data.applicationName,
          size: "35px",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }