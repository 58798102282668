var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _vm.userHasPermission("company_apps_create")
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mr-4 sm:mb-0 mb-2",
                          attrs: { disabled: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("Add")]
                      ),
                      _c("vs-input", {
                        staticClass:
                          "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                        attrs: { placeholder: "Search..." },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function ($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.applicationData,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true,
              },
              on: { "selection-changed": _vm.onSelectionChanged },
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: 10 },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }